import firebase from "firebase/app";
require('firebase/auth')
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyClfF6DGDMlB5kXYx9h0YcNUNF8RAmzBuA",
  authDomain: "meerbouw-1b3c0.firebaseapp.com",
  databaseURL: "https://meerbouw-1b3c0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "meerbouw-1b3c0",
  storageBucket: "meerbouw-1b3c0.appspot.com",
  messagingSenderId: "433964165928",
  appId: "1:433964165928:web:031e113c3fc41bb5397e1d",
  measurementId: "G-1TEGFHTGV0"
};


var firebaseApp = firebase.initializeApp(firebaseConfig);

// Default only exported the database. But when auth needed, it could only fetch the database.
// Fixed by just changing the services and returning the whole firebase object. (could be split into multiple exports, but eh?)
export default firebaseApp;